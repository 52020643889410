<template>
    <div :class="['registration-form', layout ? `registration-form--${layout}` : '']">
        <template v-if="loading">
            <Loading></Loading>
        </template>
        <template v-else-if="!success">
            <div class="alert alert-danger" v-if="error">{{ error }}</div>

            <form action="#" @submit.prevent="onFormSubmit()">
                <div class="row">
                    <div class="col-12" :class="{'col-md-6': layout === 'modal'}">
                        <TextField v-model="data.firstName" placeholder="First name" :validator="$v.data.firstName" required></TextField>
                    </div>

                    <div class="col-12" :class="{'col-md-6': layout === 'modal'}">
                        <TextField v-model="data.lastName" placeholder="Last name" :validator="$v.data.lastName" required></TextField>
                    </div>

                    <div class="col-12">
                        <TextField v-model="data.email" placeholder="E-mail address" :validator="$v.data.email" required></TextField>
                    </div>

                    <div class="col-12">
                        <TextField v-model="data.emailConfirm" placeholder="Confirm e-mail address" :validator="$v.data.emailConfirm" required></TextField>
                    </div>

                    <div class="col-12">
                        <TextField v-model="data.password" type="password" type-switch="text" placeholder="Password" :validator="$v.data.password" required></TextField>
                    </div>

                    <div class="form-group col-12">
                        <Checkbox v-model="data.terms" :validator="$v.data.terms" required>
                            I have read and accepted the <a :href="termsUrl" title="Terms & Conditions" target="_blank" class="link-underline font-weight-bold">Terms & Conditions</a> and <a :href="privacyPolicyUrl" title="Privacy Policy" target="_blank" class="link-underline font-weight-bold">Privacy Policy</a>.
                        </Checkbox>
                    </div>

                    <div class="form-group-lg col-12" v-if="this.$config.billingAddress && this.$config.shortAddress">
                        <div class="registration-form-newsletter">
                            <Checkbox v-model="data.newsletter">I would like to subscribe to the newsletter.</Checkbox>

                            <div class="registration-form-newsletter__content">
                                <div>The controller of your personal data is: <strong>{{ this.$config.shortAddress }}</strong>.</div>

                                <details>
                                    <summary>Please read the full disclaimer on data processing, available here</summary>
                                    <p>We process the following categories of your personal data: e-mail address. The controller of personal data is {{ this.$config.billingAddress }}, e-mail: <a href="mailto:info@veello.com">info@veello.com</a>. Your personal data will be processed for the purpose of direct marketing (Article 6(1)(f) of the GDPR) by the Controller. The personal data will be kept until such time as you unsubscribe from the newsletter or file an objection to its processing for this purpose. Providing your personal data is voluntary, but necessary for us to send you messages. You have the right of access to, rectification, and erasure of your personal data, and of restriction of the processing thereof. You also have the right to data portability and the right to object to the processing of your personal data. You have the right to lodge a complaint with the President of the Polish Personal Data Protection Office. Personal data will be provided only to our trusted subcontractors, i.e. IT service providers.</p>
                                </details>
                            </div>
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary" v-if="layout === 'checkout'">Register Now</button>

                    <div class="col-12 text-center" v-else>
                        <button type="submit" class="btn btn-primary btn-icon"><span>Register Now</span><i class="icon icon-user"></i></button>
                    </div>
                </div>
            </form>
        </template>
        <template v-else>
            <Loading iconClass="icon-user-plus">
                <template v-slot:title>
                    Your account has been created!
                </template>
                <template v-slot:text>
                    Please wait while we log you in …
                </template>
            </Loading>
        </template>
    </div>
</template>

<script>
    import qs from 'querystring';
    import {
        required,
        email,
        sameAs,
        minLength,
        helpers,
    } from 'vuelidate/lib/validators';
    import {handleAjaxError} from '../_helpers/error-handler';
    import Loading from "./Loading";
    import Checkbox from './Checkbox';
    import TextField from './TextField';
    import SelectMenu from './SelectMenu';

    export default {
        name: 'RegistrationForm',
        components: {
            Loading,
            Checkbox,
            TextField,
            SelectMenu
        },
        props: {
            privacyPolicyUrl: { type: String, required: true },
            registrationUrl: { type: String, required: true },
            termsUrl: { type: String, required: true },
            minPasswordLength: { type: Number, required: true },
            layout: {type: String, default: ''},
        },
        data() {
            return {
                error: false,
                success: false,
                loading: false,
                data: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    emailConfirm: null,
                    password: null,
                    terms: false,
                    newsletter: false,
                },
                errors: {
                    email: null,
                    emailConfirm: null,
                    password: null,
                },
            };
        },
        watch: {
            'data.email': function () {
                this.errors.email = null;
            },
            'data.emailConfirm': function () {
                this.errors.email = null;
                this.errors.emailConfirm = null;
            },
            'data.password': function () {
                this.errors.password = null;
            },
        },
        computed: {
            countries() {
                const countries = [];

                for (const [code, name] of Object.entries(this.$config.countries)) {
                    countries.push({ value: code, label: name });
                }

                return countries;
            },
        },
        validations() {
            return {
                data: {
                    firstName: {required},
                    lastName: {required},
                    email: {
                        required,
                        email,
                        serverValidator: this.getServerValidator(this.errors.email),
                    },
                    emailConfirm: {
                        required,
                        sameAs: sameAs('email'),
                    },
                    password: {
                        required,
                        minLength: minLength(this.minPasswordLength),
                        serverValidator: this.getServerValidator(this.errors.password),
                    },
                    terms: {required},
                }
            };
        },
        methods: {
            getServerValidator(message) {
                return helpers.withParams({ type: 'serverValidator', message }, value => !helpers.req(value) || (value && !message));
            },
            onFormSubmit() {
                this.$v.data.$touch();

                if (this.$v.data.$invalid) {
                    return;
                }

                this.loading = true;

                this.$http.post(this.registrationUrl, {...this.data}).then(() => {
                    this.success = true;

                    this.$http.post(window.location.href, qs.stringify({
                        username: this.data.email,
                        password: this.data.password,
                        FORM_SUBMIT: 'tl_login',
                        _target_path: btoa(window.location.origin + window.location.pathname),
                    }), {
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    }).then(() => {
                        this.success = true;
                        history.replaceState(null, null, window.location.pathname + window.location.search);
                        window.location.reload();
                    }).catch(error => {
                        if (error.response.status === 401) {
                            this.error = error.response.data;
                        } else {
                            handleAjaxError(error);
                        }
                    });
                }).catch(error => {
                    if (error.response.status === 400 && typeof error.response.data === 'object') {
                        this.errors = {...error.response.data};
                    } else {
                        handleAjaxError(error);
                    }
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
    }
</script>
