var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("div", { class: { "form-label-group": _vm.label } }, [
        _c(
          "select",
          {
            class: _vm.cssClass,
            attrs: {
              id: _vm.id,
              required: _vm.required,
              disabled: _vm.disabled
            },
            on: {
              change: function($event) {
                _vm.$emit("input", $event.target.value) &&
                  _vm.validator && _vm.validator.$touch()
              },
              blur: function($event) {
                _vm.validator && _vm.validator.$touch()
              }
            }
          },
          [
            _vm.blankOption
              ? _c("option", { attrs: { value: "" } }, [_vm._v("–")])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.optionGroups, function(optionGroup) {
              return _c(
                "optgroup",
                { attrs: { label: optionGroup.label } },
                _vm._l(optionGroup.options, function(option) {
                  return _c(
                    "option",
                    {
                      domProps: {
                        value: option.value,
                        selected: option.value === _vm.value
                      }
                    },
                    [_vm._v(_vm._s(option.label))]
                  )
                }),
                0
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.options, function(option) {
              return _c(
                "option",
                {
                  domProps: {
                    value: option.value,
                    selected: option.value === _vm.value
                  }
                },
                [_vm._v(_vm._s(option.label))]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm.label
          ? _c("label", { attrs: { for: _vm.id } }, [
              _vm._v(_vm._s(_vm.label)),
              _vm.required
                ? _c("span", { staticClass: "required" }, [_vm._v("*")])
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("FieldError", {
        attrs: { validator: _vm.validator, message: _vm.error }
      }),
      _vm._v(" "),
      _vm.hint
        ? _c("small", { staticClass: "form-hint" }, [_vm._v(_vm._s(_vm.hint))])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }