<template>
    <RegistrationForm :registration-url="this.$config.registrationUrl" :privacy-policy-url="this.$config.privacyPolicyUrl" :terms-url="this.$config.termsUrl" :min-password-length="this.$config.minPasswordLength" :layout="this.$config.layout"></RegistrationForm>
</template>

<script>
    import RegistrationForm from '../_components/RegistrationForm';

    export default {
        name: 'Registration',
        components: {
            RegistrationForm,
        }
    }
</script>
