import Vue from 'vue';
import Vuelidate from 'vuelidate';
import axios from '../_helpers/axios';
import Registration from './Registration.vue';

Vue.use(Vuelidate);
Vue.prototype.$http = axios;

const el = document.getElementById('app-registration');

if (el) {
    new Vue({
        render: h => h(Registration),
        beforeMount() {
            Vue.prototype.$config = JSON.parse(this.$el.dataset.config);
        },
    }).$mount(el);
}
