var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "registration-form",
        _vm.layout ? "registration-form--" + _vm.layout : ""
      ]
    },
    [
      _vm.loading
        ? [_c("Loading")]
        : !_vm.success
        ? [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "form",
              {
                attrs: { action: "#" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.onFormSubmit()
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-12",
                      class: { "col-md-6": _vm.layout === "modal" }
                    },
                    [
                      _c("TextField", {
                        attrs: {
                          placeholder: "First name",
                          validator: _vm.$v.data.firstName,
                          required: ""
                        },
                        model: {
                          value: _vm.data.firstName,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "firstName", $$v)
                          },
                          expression: "data.firstName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-12",
                      class: { "col-md-6": _vm.layout === "modal" }
                    },
                    [
                      _c("TextField", {
                        attrs: {
                          placeholder: "Last name",
                          validator: _vm.$v.data.lastName,
                          required: ""
                        },
                        model: {
                          value: _vm.data.lastName,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "lastName", $$v)
                          },
                          expression: "data.lastName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("TextField", {
                        attrs: {
                          placeholder: "E-mail address",
                          validator: _vm.$v.data.email,
                          required: ""
                        },
                        model: {
                          value: _vm.data.email,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "email", $$v)
                          },
                          expression: "data.email"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("TextField", {
                        attrs: {
                          placeholder: "Confirm e-mail address",
                          validator: _vm.$v.data.emailConfirm,
                          required: ""
                        },
                        model: {
                          value: _vm.data.emailConfirm,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "emailConfirm", $$v)
                          },
                          expression: "data.emailConfirm"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("TextField", {
                        attrs: {
                          type: "password",
                          "type-switch": "text",
                          placeholder: "Password",
                          validator: _vm.$v.data.password,
                          required: ""
                        },
                        model: {
                          value: _vm.data.password,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "password", $$v)
                          },
                          expression: "data.password"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group col-12" },
                    [
                      _c(
                        "Checkbox",
                        {
                          attrs: { validator: _vm.$v.data.terms, required: "" },
                          model: {
                            value: _vm.data.terms,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "terms", $$v)
                            },
                            expression: "data.terms"
                          }
                        },
                        [
                          _vm._v(
                            "\n                        I have read and accepted the "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "link-underline font-weight-bold",
                              attrs: {
                                href: _vm.termsUrl,
                                title: "Terms & Conditions",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Terms & Conditions")]
                          ),
                          _vm._v(" and "),
                          _c(
                            "a",
                            {
                              staticClass: "link-underline font-weight-bold",
                              attrs: {
                                href: _vm.privacyPolicyUrl,
                                title: "Privacy Policy",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Privacy Policy")]
                          ),
                          _vm._v(".\n                    ")
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  this.$config.billingAddress && this.$config.shortAddress
                    ? _c("div", { staticClass: "form-group-lg col-12" }, [
                        _c(
                          "div",
                          { staticClass: "registration-form-newsletter" },
                          [
                            _c(
                              "Checkbox",
                              {
                                model: {
                                  value: _vm.data.newsletter,
                                  callback: function($$v) {
                                    _vm.$set(_vm.data, "newsletter", $$v)
                                  },
                                  expression: "data.newsletter"
                                }
                              },
                              [
                                _vm._v(
                                  "I would like to subscribe to the newsletter."
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "registration-form-newsletter__content"
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "The controller of your personal data is: "
                                  ),
                                  _c("strong", [
                                    _vm._v(_vm._s(this.$config.shortAddress))
                                  ]),
                                  _vm._v(".")
                                ]),
                                _vm._v(" "),
                                _c("details", [
                                  _c("summary", [
                                    _vm._v(
                                      "Please read the full disclaimer on data processing, available here"
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "We process the following categories of your personal data: e-mail address. The controller of personal data is " +
                                        _vm._s(this.$config.billingAddress) +
                                        ", e-mail: "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "mailto:info@veello.com"
                                        }
                                      },
                                      [_vm._v("info@veello.com")]
                                    ),
                                    _vm._v(
                                      ". Your personal data will be processed for the purpose of direct marketing (Article 6(1)(f) of the GDPR) by the Controller. The personal data will be kept until such time as you unsubscribe from the newsletter or file an objection to its processing for this purpose. Providing your personal data is voluntary, but necessary for us to send you messages. You have the right of access to, rectification, and erasure of your personal data, and of restriction of the processing thereof. You also have the right to data portability and the right to object to the processing of your personal data. You have the right to lodge a complaint with the President of the Polish Personal Data Protection Office. Personal data will be provided only to our trusted subcontractors, i.e. IT service providers."
                                    )
                                  ])
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.layout === "checkout"
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Register Now")]
                      )
                    : _c("div", { staticClass: "col-12 text-center" }, [
                        _vm._m(0)
                      ])
                ])
              ]
            )
          ]
        : [
            _c("Loading", {
              attrs: { iconClass: "icon-user-plus" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n                Your account has been created!\n            "
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "text",
                  fn: function() {
                    return [
                      _vm._v(
                        "\n                Please wait while we log you in …\n            "
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "btn btn-primary btn-icon", attrs: { type: "submit" } },
      [
        _c("span", [_vm._v("Register Now")]),
        _c("i", { staticClass: "icon icon-user" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }