<template>
    <div class="form-group">
        <div :class="{ 'form-label-group': label }">
        <select
            :id="id"
            :class="cssClass"
            :required="required"
            :disabled="disabled"
            @change="$emit('input', $event.target.value) && (validator && validator.$touch())"
            @blur="validator && validator.$touch()"
        >
            <option value="" v-if="blankOption">–</option>
            <optgroup v-for="optionGroup in optionGroups" :label="optionGroup.label">
                <option v-for="option in optionGroup.options" :value="option.value" :selected="option.value === value">{{ option.label }}</option>
            </optgroup>
            <option v-for="option in options" :value="option.value" :selected="option.value === value">{{ option.label }}</option>
        </select>
        <label :for="id" v-if="label">{{ label }}<span v-if="required" class="required">*</span></label>
        </div>
        <FieldError :validator="validator" :message="error"></FieldError>
        <small class="form-hint" v-if="hint">{{ hint }}</small>
    </div>
</template>

<script>
    import slugify from 'slugify';
    import FieldError from './FieldError';

    export default {
        name: 'SelectMenu',
        components: {
            FieldError,
        },
        props: {
            blankOption: { type: Boolean, default: false },
            error: { type: String, default: '' },
            disabled: { type: Boolean, default: false },
            hint: { type: String, default: '' },
            label: { type: String, default: '' },
            options: { type: Array, default: () => [] },
            optionGroups: { type: Array, default: () => [] },
            required: { type: Boolean, default: false },
            validator: { type: Object, default: null },
            value: { type: [String, Number], default: null },
        },
        computed: {
            cssClass() {
                const classes = ['custom-select'];

                if (this.validator) {
                    if (this.validator.$error) {
                        classes.push('is-invalid');
                    } else if (this.value) {
                        classes.push('is-valid');
                    }
                }

                return classes.join(' ');
            },
            id() {
                let id = 'lbl-';

                if (this.label) {
                    id = `${id}${slugify(this.label, { lower: true })}-`;
                }

                return `${id}${this._uid}`
            }
        }
    }
</script>
