var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("RegistrationForm", {
    attrs: {
      "registration-url": this.$config.registrationUrl,
      "privacy-policy-url": this.$config.privacyPolicyUrl,
      "terms-url": this.$config.termsUrl,
      "min-password-length": this.$config.minPasswordLength,
      layout: this.$config.layout
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }